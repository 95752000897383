import React from 'react';
import './HomeComponent.scss';
import SelectPackageComponent from './SelectPackageComponent';
import {Outlet,Navigate} from 'react-router-dom';

export default class HomeComponent extends React.Component {

  render(){
    const {consumedSingleCheck,hasPackage,isAdmin,user,isLoggedIn} = this.props;
    return (
      <div className="home-component">
        {!isLoggedIn && (<Navigate to="/find-out/login" redirect="true" />)}
        {isAdmin && (<Navigate to="/admin" redirect="true" />)}
        {!hasPackage ? (<SelectPackageComponent usedSingleCheck={consumedSingleCheck} username={user} />) : (<Outlet/>)}
      </div>
    );
  }
}
