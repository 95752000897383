import React from 'react';
import "./AdminTableComponent.scss";
import { PaginationControl } from 'react-bootstrap-pagination-control';
import {Table,Form,CloseButton} from 'react-bootstrap';

export default class AdminTableComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      filter: props.filter,
    }
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.updateCriteria = this.updateCriteria.bind(this);
    this.propagateFilter = this.propagateFilter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.updateLimit = this.updateLimit.bind(this);
    this.filterDebounce = 0;
  }

  renderHeader(){
    const {header} = this.props;
    return (
      <thead>
        <tr>
          {header.map((h,n) => (<th key={`header-${n}`}>{h}</th>))}
        </tr>
      </thead>
    )
  }

  renderBody(){
    const {body} = this.props;
    return (
      <tbody>
        {body.map((b,n) => (<tr key={`body-${n}`}>{b}</tr>))}
      </tbody>
    )
  }

  propagateFilter(){
    const {filter} = this.state;
    if(this.props.updateCriteria){
      this.props.updateCriteria('filter',filter);
      this.props.updateCriteria('page',1);
    }
  }

  clearFilter(){
    this.setState({
      filter:'',
    },()=>{
      clearTimeout(this.filterDebounce);
      this.propagateFilter();
    })
  }

  updateFilter(ev){
    const {target:{value}} = ev;
    this.setState({
      filter:value,
    },()=>{
      clearTimeout(this.filterDebounce);
      this.filterDebounce = setTimeout(this.propagateFilter,1000);
    })
  }

  updateCriteria(name,value){
    this.props.updateCriteria && this.props.updateCriteria(name,value);
  }

  updateLimit(newLimit){
    if(this.props.updateCriteria){
      this.props.updateCriteria('limit',newLimit);
      this.props.updateCriteria('page',1);
    }
  }

  render(){
    const {pagination:{page,total,limit},isFetching} = this.props;
    const {filter} = this.state;
    return (
      <div className="admin-table">
        <div className="form-container">
          <Form.Control
            type="text"
            name="filter"
            placeholder="Szukaj..."
            value={filter}
            disabled={!!isFetching}
            onChange={this.updateFilter}
          />
          <CloseButton onClick={this.clearFilter}/>
        </div>
        <Table striped responsive bordered hover>
          {this.renderHeader()}
          {this.renderBody()}
        </Table>
        <PaginationControl
          page={page}
          between={1}
          total={total}
          limit={limit}
          changePage={(page) => {
            this.updateCriteria('page',page)
          }}
          ellipsis={1}
        />
        <Form.Select
          onChange={(ev)=>this.updateLimit(ev.target.value)}
          name="limit"
          defaultValue={limit}
          disabled={!!isFetching}
        >
          {[1,5,10,15,20,50].map((i,n) => (<option key={`limit-${n}`} value={i}>Limit: {i}</option>))}
        </Form.Select>
      </div>
    )
  }
}
