import React from 'react';
import "./StaticPagesComponent.scss";
import {Table,Button, Form} from 'react-bootstrap';
import {fetchData} from '../../services';
import Editor from 'react-simple-wysiwyg';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import SingleStaticPageComponent from './SingleStaticPageComponent';
const {REACT_APP_BACKEND_URL} = process.env;

export default class StaticPagesComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      pages: [],
      newPageName: '',
      newPageContent: '',
      isMainMenu: false,
    }
    this.fetchPages = this.fetchPages.bind(this);
    this.addNewPage = this.addNewPage.bind(this);
    this.updateStaticPageContent = this.updateStaticPageContent.bind(this);
  }

  componentDidMount(){
    this.fetchPages();
  }

  fetchPages(){
    fetchData(`${REACT_APP_BACKEND_URL}/api/static-pages/all`)
      .then(data=>this.setState({
        pages:data.pages,
      }))
      .catch(err=>console.warn(err));
  }

  removePage(pageId){
    if(window.confirm("Usunąć wybraną stronę?")){
      if(window.confirm("Strony powinno się usuwać bardzo ostrożnie, np. z przyczyn testowych. Czy usunąć?")){
        fetchData(`${REACT_APP_BACKEND_URL}/api/static-pages/remove/${pageId}`,'DELETE')
          .then(data=>!!data.success && this.fetchPages())
          .catch(err=>console.warn(err));
      }
    }
  }

  addNewPage(ev){
    ev.preventDefault();
    const {newPageName,newPageContent,isMainMenu} = this.state;
    const data = {
      pageName:newPageName,
      pageContent:newPageContent,
      isMainMenu: isMainMenu,
    };
    fetchData(`${REACT_APP_BACKEND_URL}/api/static-pages/new`,'POST',data)
      .then(data=>{
        if(data.success){
          this.fetchPages()
          this.setState({
            newPageName: '',
            newPageContent: '',
            isMainMenu: false,
          })
        }
      })
      .catch(err=>console.warn(err));
  }

  updateStaticPageContent(id,newContent,toggleMenu=false){
    const data = {};
    if(newContent) data['content'] = newContent;
    if(toggleMenu) data['toggleMenu'] = true;
    fetchData(`${REACT_APP_BACKEND_URL}/api/static-pages/${id}/update`,'PATCH',data)
      .then(data=>!!data.success && this.fetchPages())
      .catch(err=>console.warn(err));
  }

  renderSinglePage(p,k){
    return (
      <tr key={k}>
        <td>{k+1}</td>
        <td>{p.id}<Button variant="warning" onClick={()=>this.removePage(p.id)}>Usuń</Button></td>
        <td>{p.name}</td>
        <td><SingleStaticPageComponent content={p.content} onChange={(content)=>this.updateStaticPageContent(p.id,content)}/></td>
        <td>
          <BootstrapSwitchButton
            size={"lg"}
            checked={p.isMainMenu}
            onlabel='Tak'
            offlabel='Nie'
            onChange={(checked: boolean) => {
                this.updateStaticPageContent(p.id,null,true);
            }}
          />
        </td>
      </tr>
    )
  }

  renderPages(){
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>LP</th>
            <th>Id</th>
            <th>Nazwa</th>
            <th>Zawartość</th>
            <th>Czy menu główne?</th>
          </tr>
        </thead>
        <tbody>
          {this.state.pages.map((p,k) => this.renderSinglePage(p,k))}
        </tbody>
      </Table>
    )
  }

  updateItem(item,value){
    this.setState({
      [item]:value,
    })
  }

  renderAddPagePanel(){
    const {newPageName,newPageContent,isMainMenu} = this.state;
    return (
      <Form onSubmit={this.addNewPage}>
        <Form.Group>
          <Form.Label>Nazwa strony</Form.Label>
          <Form.Control
            type="Text"
            value={newPageName}
            placeholder="Nazwa strony"
            onChange={(ev)=>this.updateItem('newPageName',ev.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Zawartość strony</Form.Label>
          <Editor
            value={newPageContent}
            onChange={ev=>this.updateItem('newPageContent',ev.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Czy w menu głównym?</Form.Label>
          <Form.Check
            type="checkbox"
            value={isMainMenu}
            onChange={ev=>this.updateItem('isMainMenu',ev.target.checked)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Dodaj
        </Button>
      </Form>
    )
  }

  render(){
    return (
      <div className="settings-list">
        <h3>Strony statyczne:</h3>
        {this.renderPages()}
        {this.renderAddPagePanel()}
      </div>
    )
  }
}
