import React from 'react';
import { Form, Button } from 'react-bootstrap';
import './HomeComponent.scss';
import './Communicators.scss';
import Logo from '../../assets/logo_blank.png';
import AccountImage from '../../assets/myaccount.png';
import { Link } from 'react-router-dom';
import {fetchData} from '../../services';
const {REACT_APP_BACKEND_OPEN_URL} = process.env;

const basicUser = {
  userName: '',
  surname: '',
  phone: '',
  email: '',
  nickname: '',
};
const communicators = {
  messenger: '',
  instagram: '',
  whatsapp: '',
  snapchat: '',
}
const placeholders = {
  userName: 'Imię',
  surname: 'Nazwisko',
  phone: 'Telefon',
  email: 'Email',
  nickname: 'Nickname',
  messenger: 'Messenger',
  instagram: 'Instagram',
  whatsapp: 'Whatsapp',
  snapchat: 'Snapchat',
};
const disabledEdition = ['email'];
const communicatorsKeys = Object.keys(communicators);
const basicUserKeys = Object.keys(basicUser);
const userKeys = [...basicUserKeys,...communicatorsKeys];

export default class MainComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isLoading: false,
      ...basicUser,
      ...communicators,
    }
    this.fetchUser = this.fetchUser.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.handleUpdateUser = this.handleUpdateUser.bind(this);
  }

  componentDidMount(){
    this.fetchUser();
  }

  fetchUser(){
    this.setState({isLoading: true})
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/user/api/me/my-account/get`)
      .then(data=>{
        let newData = {};
        userKeys.forEach(k => newData[k] = data.user[k]);
        this.setState({
          ...newData,
        })
      })
      .finally(()=>{
        this.setState({isLoading: false});
      })
  }

  removeUser(){
    if('tak' === window.prompt("Czy jesteś pewien? Aby potwierdzić usunięcie konta wpisz słowo 'tak':")){
      this.setState({isLoading: true})
      fetchData(`${REACT_APP_BACKEND_OPEN_URL}/user/api/me/my-account/delete`,'DELETE')
        .then(data=>{
          if(data.success) window.location.replace('/logout');
        })
        .finally(()=>{
          this.setState({isLoading: false});
        })
    }
  }

  updateUser(ev){
    const {target:{name,value}} = ev;
    ev.preventDefault();
    this.setState({[name]:value});
  }

  handleUpdateUser(ev){
    ev.preventDefault();
    this.setState({isLoading:true});
    let data = {};
    userKeys.forEach(k => data[k]=this.state[k]);
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/user/api/me/my-account`,'PATCH',data)
      .then(data=>!!data.success && this.fetchUser())
      .finally(()=>{
        this.setState({isLoading: false});
      })
  }

  renderFormItem(k,buk,isCommunicator=false){
    const {isLoading} = this.state;
    return (
      <Form.Group key={k} className={isCommunicator ? `${buk} is-communicator` : ''}>
        <Form.Control
          placeholder={placeholders[buk]}
          type="text"
          name={buk}
          value={this.state[buk]}
          disabled={!!isLoading || disabledEdition.indexOf(buk)>-1}
          onChange={this.updateUser}
        />
        {isCommunicator && (<span className="icon"/>)}
      </Form.Group>
    )
  }

  renderMyForm(){
    return (
      <Form onSubmit={this.handleUpdateUser}>
        {basicUserKeys.map((buk,k) => this.renderFormItem(k,buk))}
        <h3>Komunikatory</h3>
        {communicatorsKeys.map((cK,k) => this.renderFormItem(k,cK,true))}
        <Button className="my" type="submit">Zapisz</Button>
        <Button className="delete" onClick={this.removeUser} type="button">Usuń konto</Button>
      </Form>
    )
  }

  render(){
    return (
      <div className="my-account-component">
        <img src={Logo} alt="Logo" className="logo" />
        <h3>Moje konto</h3>
        {this.renderMyForm()}
        <img src={AccountImage} alt="My account" />
        <Link className="mainmenu" to="/find-out/app"><span>Menu główne</span></Link>
      </div>
    );
  }
}
