import React, {useState, useEffect} from 'react';
import {fetchData} from '../../services';
import {Alert,Table, Badge} from 'react-bootstrap';
import LoaderComponent from '../../Loader/LoaderComponent';
const {REACT_APP_BACKEND_URL} = process.env;

export default function AdminDashboardComponent(props) {
  const [data,setData] = useState({});
  const [isLoading,setIsLoading] = useState(false);

  useEffect(() => fetchDashboardData(), []);

  const fetchDashboardData = () => {
    setIsLoading(true);
    fetchData(`${REACT_APP_BACKEND_URL}/api/dashboard`)
      .then(data=>{if(data.success){
        setData(data.data);
      }})
      .catch(err => console.warn(err))
      .finally(() => setIsLoading(false));
  }

  const renderDashboardData = () => {
    return (
      <Table striped bordered hover>
        <tbody>
          <tr>
            <td>Ilość zareejstrowanych użytkowników: </td>
            <td><Badge>{data.numberOfUsers}</Badge></td>
          </tr>
          <tr>
            <td>Ilość niepotwierdzonych kont użytkowników: </td>
            <td><Badge>{data.numberOfNotConfirmedUser}</Badge></td>
          </tr>
          <tr>
            <td>Ilość potwierdzonych kont użytkowników: </td>
            <td><Badge>{data.numberOfConfirmedUsers}</Badge></td>
          </tr>
          <tr>
            <td>Ilość zakupionych pakietów: </td>
            <td><Badge>{data.numberOfPackages}</Badge></td>
          </tr>
          <tr>
            <td>Ilość wykonanych sprawdzeń testowych: </td>
            <td><Badge>{data.numberOfSingleChecks}</Badge></td>
          </tr>
          <tr>
            <td>Ilość wykonanych sprawdzeń komercyjnych (dodanych relacji): </td>
            <td><Badge>{data.numberOfChecks}</Badge></td>
          </tr>
        </tbody>
      </Table>
    )
  }

  return (
    <div className="adminDashboard">
      <Alert>Find Out - panel administratora</Alert>
      {isLoading ? <LoaderComponent/> : renderDashboardData()}
    </div>
  )
}
