import React from 'react';
import "./FrontEndHeaderComponent.scss";
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Logo from './assets/logo_blank.png';
import LogoWhite from './assets/logo_blank_white.png';
import { fetchData } from './services';

const { REACT_APP_BACKEND_OPEN_URL } = process.env;

export default class FrontEndHeaderCompnent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      urls: [],
    };
    this.fetchMenu = this.fetchMenu.bind(this);
  }

  componentDidMount(){
    this.fetchMenu();
  }

  fetchMenu(){
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/static-pages/top`)
      .then(data=>this.setState({
        urls: data.urls,
      }))
      .catch(err=>console.warn(err));
  }

  render(){
    const {isRed,user,isAppAvailable} = this.props;
    return (
      <header>
        <Link to="/">
          {isRed ? (<img src={LogoWhite} alt="Find Out!" />) : (<img src={Logo} alt="Find Out!" />)}
        </Link>
        <nav>
          {this.state.urls.map((u,n) => (<Link key={`url${n}`} to={`/${u.id}/${u.query}`}>{u.name}</Link>))}
        </nav>
        {isAppAvailable &&
          (!!user ? (<Link to="/find-out/app" className="login-link"><Button>{user}</Button></Link>)
          :
          (<Link to="/find-out" className="login-link"><Button>Logowanie</Button></Link>))
        }
      </header>
    )
  }
}
