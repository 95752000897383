import React from 'react';
import { Navigate } from 'react-router-dom';
import {fetchData} from '../../services';
import EmptyRelationForm from './Forms/EmptyRelationForm';
import LoaderComponent from '../../Loader/LoaderComponent';
import {configKeys} from './relation.data';
const {REACT_APP_BACKEND_OPEN_URL} = process.env;

export default class AddRelationComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isLoading: false,
      detailsId: null,
    }
    this.createRelation = this.createRelation.bind(this);
  }

  createRelation(ev,state){
    ev.preventDefault();
    let data = {};
    configKeys.forEach(k => data[k] = state[k]);
    this.setState({isLoading:true});
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/user/api/relation/new`,'POST',data)
      .then(data=>{
        if(data){
          if(data.errorMessage) window.alert(data.errorMessage);
          if(data.success){
            this.setState({
              detailsId: data.relationId,
            });
          }
        }
      })
      .finally(()=>{
        this.setState({isLoading: false});
      })
  }

  render(){
    const {isLoading,detailsId} = this.state;
    return isLoading ? <LoaderComponent/> : detailsId ? (<Navigate to={`/find-out/app/relation-details/${detailsId}`} redirect={true} />) : (
      <EmptyRelationForm
        onSubmit={this.createRelation}
      />
    );
  }
}
