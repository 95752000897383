import React from 'react';
import { Link } from 'react-router-dom';
import Partners from './assets/partners.png';
import Mobile from './assets/mobile.png';
import Facebook from './assets/social-facebook.png';
import Youtube from './assets/social-youtube.png';
import Instagram from './assets/social-instagram.png';
import Something from './assets/social-something.png';
import FacebookW from './assets/social-facebook_w.png';
import YoutubeW from './assets/social-youtube_w.png';
import InstagramW from './assets/social-instagram_w.png';
import SomethingW from './assets/social-something_w.png';
import FrontEndHeaderComponent from './FrontEndHeaderComponent';
import FrontEndFooterComponent from './FrontEndFooterComponent';
import { Button, Carousel } from 'react-bootstrap';
import "./FrontEndComponent.scss";
import { fetchData } from './services';

const { REACT_APP_BACKEND_OPEN_URL } = process.env;
const INITIAL_LABEL_WIDTH = 45;
const INITIAL_LABEL_SPEED = 50;

export default class FrontEndComponent extends React.Component {
  constructor(props){
    super(props);
    this.state ={
      labelWidth: INITIAL_LABEL_WIDTH,
      labelChangeDirection: -1,
      activeCarouselIndex: 0,
      labels: [],
      socialMedia: {},
      basicSettings: {
        isRed: false,
      },
      daysToStart: 0,
      hoursToStart: 0,
      minutesToStart: 0,
      secondsToStart: 0,
    }
    this.modifyLabelWidth = this.modifyLabelWidth.bind(this);
    this.startTimeInterval = this.startTimeInterval.bind(this);
  }

  componentDidMount(){
    this.interval = setInterval(this.modifyLabelWidth,INITIAL_LABEL_SPEED);
    this.timerInterval = setInterval(this.startTimeInterval,1000);
    this.getLabels();
    this.getSocialMedia();
    this.getBasicSettings();
  }

  componentWillUnmount(){
    clearInterval(this.interval);
    clearInterval(this.timerInterval);
  }

  getBasicSettings(){
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/settings/get-basic-settings`)
      .then(data=>this.setState({
        basicSettings: data.basicSettings,
      }))
      .catch(err=>console.warn(err));
  }

  startTimeInterval(){
    let startDate = new Date('2024-11-15 15:00:00');
    let now = new Date();
    let diff = (startDate - now);
    let nrOfMinutes = 1000*60;
    let nrOfHours = nrOfMinutes*60;
    let nrOfDays = nrOfHours*24;
    let daysToStart = Math.floor(diff/(nrOfDays));
    let hoursToStart = Math.floor(diff/(nrOfHours)) - (daysToStart*24);
    let minutesToStart = Math.floor(diff/(nrOfMinutes)) - ((daysToStart*24*60) + (hoursToStart*60));
    let secondsToStart = Math.floor(diff/(1000)) - (daysToStart*24*60*60) - (hoursToStart*60*60) - (minutesToStart*60);
    this.setState({
      daysToStart,
      hoursToStart,
      minutesToStart,
      secondsToStart,
    })
  }

  getLabels(){
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/settings/get-labels`)
      .then(data=>this.setState({
        labels:data.labels,
      }))
      .catch(err=>console.warn(err));
  }

  getSocialMedia(){
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/settings/get-social-media`)
      .then(data=>this.setState({
        socialMedia:data.socialmedia,
      }))
      .catch(err=>console.warn(err));
  }

  modifyLabelWidth(){
    const {labelWidth, labelChangeDirection, activeCarouselIndex, labels} = this.state;
    let newActiveCarouselIndex = activeCarouselIndex;
    if(0>=labelWidth){
      if(0==labelWidth){
        newActiveCarouselIndex = (labels?.length-1<=newActiveCarouselIndex) ? 0 : newActiveCarouselIndex+1;
      }
      this.setState({
        labelWidth: labelWidth+labelChangeDirection,
        labelChangeDirection: 1,
        activeCarouselIndex: newActiveCarouselIndex,
      });
    } else if (INITIAL_LABEL_WIDTH<=labelWidth){
      this.setState({
        labelWidth: labelWidth+labelChangeDirection,
        labelChangeDirection: -1,
      });
    } else this.setState({
      labelWidth: labelWidth+labelChangeDirection,
    })
  }

  render() {
    const {labelWidth,activeCarouselIndex,labels,socialMedia,basicSettings:{isRed}, daysToStart, hoursToStart, minutesToStart, secondsToStart} = this.state;
    const {isAppAvailable} = this.props;
    const {user} = this.props;
    return (
      <main className={`front-end${isRed?' is-red':''}`}>
        <FrontEndHeaderComponent isRed={isRed} user={user} isAppAvailable={isAppAvailable}/>
        <section>
          <Carousel slide={false} indicators={false} interval={null} activeIndex={activeCarouselIndex} style={{width: labelWidth + 'rem'}}>
            {labels.map((k,l) => (
              <Carousel.Item key={`label${l}`}>
                <Carousel.Caption>
                  <h3>{k}<span className="caret" style={{visibility:labelWidth%2 ? 'visible' : 'hidden'}}>_</span></h3>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
          <div className="images">
            <img src={Partners} alt="Relacja" />
            <img src={Mobile} alt="Aplikacja" />
          </div>
          <div className="right-section">
            {isAppAvailable && (<nav>
              <Link to="/find-out">
                <Button className="login">Logowanie</Button>
              </Link>
              <Link to="/find-out/register">
                <Button className="register">Rejestracja</Button>
              </Link>
            </nav>)}
            <div className="socials">
              <h5>Śledź nas:</h5>
              <div className="social-icons">
                <Link to={socialMedia['facebook'] || '/'} target="_blank">
                  {isRed ? (<img src={FacebookW} alt="Facebook" />):(<img src={Facebook} alt="Facebook" />)}
                </Link>
                <Link to={socialMedia['youtube'] || '/'} target="_blank">
                  {isRed ? (<img src={YoutubeW} alt="Youtube" />):(<img src={Youtube} alt="Youtube" />)}
                </Link>
                <Link to={socialMedia['instagram'] || '/'} target="_blank">
                  {isRed ? (<img src={InstagramW} alt="Instagram" />):(<img src={Instagram} alt="Instagram" />)}
                </Link>
                <Link to={socialMedia['tiktok'] || '/'} target="_blank">
                  {isRed ? (<img src={SomethingW} alt="Something" />):(<img src={Something} alt="Something" />)}
                </Link>
              </div>
            </div>
          </div>
        </section>
        <FrontEndFooterComponent isRed={isRed}/>
      </main>
    );
  }
}
