import React from 'react';
import LoaderComponent from '../../Loader/LoaderComponent';
import RelationDetailsFormComponent from './Forms/RelationDetailsForm';
import { useParams, Navigate } from 'react-router-dom';
import {fetchData} from '../../services';
const {REACT_APP_BACKEND_OPEN_URL} = process.env;

function withParams(Component){
  return props => <Component {...props} params={useParams()}/>;
}

class RelationDetailsComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      relationDetails: {},
      relations: [],
      isLoading: false,
      redirectToChat: null,
    }
    this.fetchRelation = this.fetchRelation.bind(this);
    this.onSelectPerson = this.onSelectPerson.bind(this);
  }

  componentDidMount(){
    this.fetchRelation();
  }

  fetchRelation(){
    const {params:{id},isFreeCheck} = this.props;
    let url = `${REACT_APP_BACKEND_OPEN_URL}/user/api/relation/`;
    url+= isFreeCheck ? `free-check` : `${id}/get-relations`;
    this.setState({isLoading: true});
    fetchData(url)
      .then(data=>{
        this.setState({
          relationDetails: data.relationDetails,
          relations: data.relations,
        })
      })
      .finally(()=>{
        this.setState({isLoading: false});
      })
  }

  onSelectPerson(ev){
    const {target:{value}} = ev;
    this.setState({
      redirectToChat: value,
    })
  }

  render(){
    const {relationDetails,relations,isLoading,redirectToChat} = this.state;
    return isLoading ? (<LoaderComponent/>) : redirectToChat ? (<Navigate to={`/find-out/app/relation-chat/${redirectToChat}`} redirect={true} />) : (
      <RelationDetailsFormComponent
        relationDetails={relationDetails}
        relations={relations}
        onSelectPerson={this.onSelectPerson}
      />
    );
  }
}

export default withParams(RelationDetailsComponent);
