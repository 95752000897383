import React from 'react';
import './LoginComponent.scss';
import Logo from '../assets/logo_blank.png';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { Link, Navigate } from 'react-router-dom';
import {fetchData} from '../services';
const {REACT_APP_BACKEND_OPEN_URL} = process.env;

export default class LoginComponent extends React.Component {

  constructor(props){
    super(props);
    this.state={
      login:'',
      password:'',
      loginFailed: false,
      isLoading: false,
    }
    this.updateItem = this.updateItem.bind(this);
    this.submitLogin = this.submitLogin.bind(this);
  }

  updateItem(item,ev){
    this.setState({[item]:ev.target.value});
  }

  submitLogin(ev){
    ev.preventDefault();
    this.setState({isLoading:true, loginFailed:false});
    let data = {'username':this.state.login,'password':this.state.password};
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/login`,'POST',data,false)
      .then(data => data.success ? window.location.replace("/find-out/app") : this.setState({loginFailed:true}))
      .finally(()=>this.setState({isLoading:false}))
  }

  render(){
    const {isLoggedIn} = this.props;
    return (
      <div className="login-panel">
        {isLoggedIn && (<Navigate to="/find-out/app" redirect={true} />)}
        {this.state.loginFailed && (<Alert key="warning" variant="warning">Podane dane są nieprawidłowe</Alert>)}
        <Form onSubmit={this.submitLogin}>
          <Link to="/"><img src={Logo} className="logo" alt="Logo"/></Link>
          <span>Utwórz nowe konto lub zaloguj się za pomocą istniejącego konta</span>
          <Form.Group>
            <Form.Control
              placeholder="Nazwa użytkownika"
              type="text"
              name="login"
              value={this.state.login}
              disabled={!!this.state.isLoading}
              onChange={(ev)=>this.updateItem('login',ev)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              placeholder="Hasło"
              type="password"
              name="password"
              value={this.state.password}
              disabled={!!this.state.isLoading}
              onChange={(ev)=>this.updateItem('password',ev)}
            />
          </Form.Group>
          <div className="forgot-password">
            <Link to="/find-out/reset-password">Zapomniałeś hasła?</Link>
          </div>
          <Button variant="danger" type="submit">Zaloguj się</Button>
          <div>
            <ul>
             <li className="register-link"><Link to="/find-out/register">Zarejestruj się</Link></li>
            </ul>
          </div>
        </Form>
      </div>
    )
  }
}
