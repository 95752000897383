import React from 'react';
import "./RelationTypesComponent.scss";
import {Button,Form} from 'react-bootstrap';
import {fetchData} from '../../services';
import AdminTableComponent from '../helpers/AdminTableComponent';
import LoaderComponent from '../../Loader/LoaderComponent';
const {REACT_APP_BACKEND_URL} = process.env;

export default class RelationTypesComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      relationTypes: [],
      page: 1,
      limit: 10,
      total: 0,
      orderBy: 'orderNumber',
      filter: '',
      isLoading: false,
    }
    this.fetchRelationTypes = this.fetchRelationTypes.bind(this);
    this.renderRelationTypes = this.renderRelationTypes.bind(this);
    this.updateCriteria = this.updateCriteria.bind(this);
  }

  componentDidMount(){
    this.fetchRelationTypes();
  }

  fetchRelationTypes(){
    const {page,limit,orderBy,filter} = this.state;
    this.setState({isLoading:true});
    fetchData(`${REACT_APP_BACKEND_URL}/api/relation/types/${page}/${limit}/${orderBy}/${filter}`)
      .then(data=>this.setState({
        relationTypes:data.relationTypes,
        total:data.total,
      }))
      .catch(err=>console.warn(err))
      .finally(()=>this.setState({isLoading:false}));
  }

  removeRelationType(id){
    if(window.confirm("Czy usunąć?")){
      this.setState({isLoading:true});
      fetchData(`${REACT_APP_BACKEND_URL}/api/relation/types/${id}`,'DELETE')
        .then(data=>this.fetchRelationTypes())
        .catch(err=>console.warn(err))
        .finally(()=>this.setState({isLoading:false}));
    }
  }

  updateOrderNumber(ev,rtId){
    const {target:{value}} = ev;
    const data = {'orderNumber':value};
    this.setState({isLoading:true});
    fetchData(`${REACT_APP_BACKEND_URL}/api/relation/types/${rtId}/update-on`,'PATCH',data)
      .then(data=>this.fetchRelationTypes())
      .catch(err=>console.warn(err.errorMessage))
      .finally(()=>this.setState({isLoading:false}));
  }

  updateCriteria(name,value){
    this.setState({
      [name]: value
    },()=>{
      this.fetchRelationTypes();
    })
  }

  renderSingleRelationType(r,k){
    const {isLoading} = this.state;
    return (
      <>
        <td>
          {r.id}
          <Button variant="danger" onClick={()=>this.removeRelationType(r.id)}>Usuń</Button>
        </td>
        <td>
          <Form.Control
            type="number"
            onChange={(ev)=>this.updateOrderNumber(ev,r.id)}
            value={r.orderNumber || 1}
            disabled={isLoading}
          />
        </td>
        <td>{r.name}</td>
        <td>
          {r.relationsNumber}
        </td>
      </>
    )
  }

  renderRelationTypes(){
    const {page,total,limit,filter,isLoading} = this.state;
    const header = [
      (<span>Id</span>),
      (<span>Kolejność</span>),
      (<span>Nazwa</span>),
      (<span>Ilość relacji</span>),
    ];
    const body = this.state.relationTypes.map((r,k) => this.renderSingleRelationType(r,k));
    const pagination = {
      page,total,limit
    }
    return (
      <AdminTableComponent
        header={header}
        body={body}
        pagination={pagination}
        filter={filter}
        updateCriteria={this.updateCriteria}
        isFetching={isLoading}
      />
    )
  }

  render(){
    const {isLoading} = this.state;
    return (
      <div className="relation-types-list">
        {isLoading && (<LoaderComponent/>)}
        <h3>Dostępne typy relacji:</h3>
        {this.renderRelationTypes()}
      </div>
    );
  }
}
