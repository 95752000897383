import { Link } from 'react-router-dom';
import {Container,Nav,Navbar,NavDropdown} from 'react-bootstrap';

function MenuComponent() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown title="Użytkownicy" id="nav-users">
              <NavDropdown.Item href="#"><Link to="/admin/users">Lista użytkowników</Link></NavDropdown.Item>
              <NavDropdown.Item href="#"><Link to="/admin/new-user">Nowy użytkownik</Link></NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Portale" id="nav-portals">
              <NavDropdown.Item href="#"><Link to="/admin/portals">Lista portali</Link></NavDropdown.Item>
              <NavDropdown.Item href="#"><Link to="/admin/new-portal">Dodaj nowy portal</Link></NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Relacje" id="nav-relations">
              <NavDropdown.Item href="#"><Link to="/admin/relation-types">Lista relacji</Link></NavDropdown.Item>
              <NavDropdown.Item href="#"><Link to="/admin/new-relation-type">Dodaj nową relację</Link></NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Pakiety" id="nav-accesses">
              <NavDropdown.Item href="#"><Link to="/admin/access-types">Lista pakietów</Link></NavDropdown.Item>
              <NavDropdown.Item href="#"><Link to="/admin/new-access-type">Dodaj nowy pakiet</Link></NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Płatności" id="nav-payments">
              <NavDropdown.Item href="#"><Link to="/admin/payments">Wykaz płatności</Link></NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Ustawienia strony" id="nav-page-settings">
              <NavDropdown.Item href="#"><Link to="/admin/page-settings">Podstawowe ustawienia</Link></NavDropdown.Item>
              <NavDropdown.Item href="#"><Link to="/admin/static-pages">Treść stron statycznych</Link></NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Blog" id="nav-blog">
              <NavDropdown.Item href="#"><Link to="/admin/blog-categories">Kategorie</Link></NavDropdown.Item>
              <NavDropdown.Item href="#"><Link to="/admin/entries/to-decide">Wpisy do moderacji</Link></NavDropdown.Item>
              <NavDropdown.Item href="#"><Link to="/admin/entries/approved">Wpisy zaakceptowane</Link></NavDropdown.Item>
              <NavDropdown.Item href="#"><Link to="/admin/entries/denied">Wpisy odrzucone</Link></NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/logout">Wyloguj</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default MenuComponent;
