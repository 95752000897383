import React from 'react';
import {Button} from 'react-bootstrap';
import Editor from 'react-simple-wysiwyg';

export default class SingleStaticPageComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      isValueEdited: false,
      content: this.props.content,
    }
    this.renderBasicView = this.renderBasicView.bind(this);
    this.renderEditView = this.renderEditView.bind(this);
    this.changeEditView = this.changeEditView.bind(this);
    this.updateContent = this.updateContent.bind(this);
  }

  renderBasicView(){
    return (
      <div onClick={()=>this.changeEditView(true)} className="static-content">
        {this.props.content}
      </div>
    )
  }

  changeEditView(isValueEdited){
    this.setState({
      isValueEdited,
    })
  }

  onChange(){
    this.props.onChange(this.state.content);
    this.changeEditView(false);
  }

  updateContent(ev){
    this.setState({
      content: ev.target.value,
    })
  }

  renderEditView(){
    return (
      <div className="edit-field">
        <Editor
          value={this.state.content}
          onChange={this.updateContent}
          onBlur={ev=>this.onChange(ev.target.value)}
        />
        <Button onClick={()=>this.changeEditView(false)}>Zakończ edycję</Button>
      </div>
    )
  }

  render(){
    const {isValueEdited} = this.state;
    return isValueEdited ? this.renderEditView() : this.renderBasicView();
  }
}
