import React from 'react';
import "./UsersComponent.scss";
import {Button} from 'react-bootstrap';
import {fetchData} from '../../services';
import AdminTableComponent from '../helpers/AdminTableComponent';
import LoaderComponent from '../../Loader/LoaderComponent';
const {REACT_APP_BACKEND_URL} = process.env;

export default class UsersComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      users: [],
      packages: [],
      page: 1,
      limit: 10,
      total: 0,
      orderBy: 'id',
      filter: '',
      isFetching: false,
    }
    this.fetchUsers = this.fetchUsers.bind(this);
    this.renderUsers = this.renderUsers.bind(this);
    this.changeUserAdmin = this.changeUserAdmin.bind(this);
    this.updateCriteria = this.updateCriteria.bind(this);
  }

  componentDidMount(){
    this.fetchUsers();
    this.fetchPackages();
  }

  fetchUsers(){
    const {page,limit,orderBy,filter} = this.state;
    this.setState({
      isFetching: true,
    });
    fetchData(`${REACT_APP_BACKEND_URL}/api/users/${page}/${limit}/${orderBy}/${filter}`)
      .then(data=>this.setState({
        users:data.users,
        total:data.total,
      }))
      .catch(err=>console.warn(err))
      .finally(()=>this.setState({
        isFetching: false,
      }))
  }

  fetchPackages(){
    fetchData(`${REACT_APP_BACKEND_URL}/api/user-access/types`)
      .then(data=>this.setState({
        packages:data.userAccessTypes,
      }))
      .catch(err=>console.warn(err));
  }

  changeUserAdmin(id){
    fetchData(`${REACT_APP_BACKEND_URL}/api/users/${id}/toggle-admin`,'PATCH')
      .then(data=>this.fetchUsers())
      .catch(err=>console.warn(err));
  }

  addPackage(packageId,userId){
    if(window.confirm("Dodać wybrany pakiet?")){
      fetchData(`${REACT_APP_BACKEND_URL}/api/accesses/new/${userId}/${packageId}`,'POST')
      .then(data=>!!data.success && this.fetchUsers())
      .catch(err=>console.warn(err));
    }
  }

  removePackage(packageId){
    if(window.confirm("Usunąć wybrany pakiet?")){
      fetchData(`${REACT_APP_BACKEND_URL}/api/accesses/remove/${packageId}`,'DELETE')
        .then(data=>!!data.success && this.fetchUsers())
        .catch(err=>console.warn(err));
    }
  }

  removeFreeCheck(userId){
    if(window.confirm("Usunąć sprawdzenie testowe?")){
      fetchData(`${REACT_APP_BACKEND_URL}/api/relation/free-check/${userId}/remove`,'DELETE')
        .then(data=>!!data.success && this.fetchUsers())
        .catch(err=>console.warn(err));
    }
  }

  removeUser(userId){
    if(window.confirm("Usunąć użytkownika?")){
      if('Tak' === window.prompt("Czy na pewno usunąć użytkownika? To proces nieodwracalny! Wpisz Tak:")){
        fetchData(`${REACT_APP_BACKEND_URL}/api/users/${userId}/remove`,'DELETE')
          .then(data=>!!data.success && this.fetchUsers())
          .catch(err=>console.warn(err));
      }
    }
  }

  activateUser(userId){
    fetchData(`${REACT_APP_BACKEND_URL}/api/users/${userId}/activate`,'PATCH')
      .then(data=>!!data.success && this.fetchUsers())
      .catch(err=>console.warn(err));
  }

  updateCriteria(name,value){
    this.setState({
      [name]: value
    },()=>{
      this.fetchUsers();
    })
  }

  renderSingleUser(u,k){
    const {packages} = this.state;
    const isUserActive = u.is_active;
    return (
      <>
        <td>
          {u.id}
          <Button onClick={()=>this.removeUser(u.id)}>Usuń użytkownika</Button>
        </td>
        <td>{u.name}</td>
        <td>
          {isUserActive ? 'Tak' : 'Nie'}
          {!isUserActive && (<Button onClick={()=>this.activateUser(u.id)}>Aktywuj używkownika</Button>)}
        </td>
        <td>
          {u.consumed_free_check ? 'Tak' : 'Nie'}
          {u.consumed_free_check && (
            <Button variant="danger" onClick={()=>this.removeFreeCheck(u.id)}>Usuń sprawdzenie testowe</Button>
          )}
        </td>
        <td>
          <div className={`toggle-parent ${u.is_admin ? 'active' : ''}`} onClick={()=>this.changeUserAdmin(u.id)}>
            <div className="toggle-button"></div>
          </div>
        </td>
        <td>{u.relations}</td>
        <td>{u.packages?.map((p,k) => (<><Button variant="warning" key={`rp${k}`} onClick={()=>this.removePackage(p.id)}>Usuń: {p.name}</Button><br/></>))}</td>
        <td>
          {packages?.map((p,k) => (<><Button variant="info" key={`p${k}`} onClick={()=>this.addPackage(p.id,u.id)}>Dodaj: {p.name}</Button><br/></>))}
        </td>
        <td>{!!u.lastLogged ? `${u.lastLogged.date} ${u.lastLogged.timezone}` : 'brak'}</td>
        <td>{u.lastLoggedIp || 'brak'}</td>
      </>
    )
  }

  renderUsers(){
    const {page,total,limit,filter,isFetching} = this.state;
    const header = [
      (<span>Id</span>),
      (<span>Email</span>),
      (<span>Aktywny?</span>),
      (<span>Czy doonał sprawdzenia testowego?</span>),
      (<span>Czy admin?</span>),
      (<span>Ilość relacji</span>),
      (<span>Pakiety</span>),
      (<span>Dodaj pakiet</span>),
      (<span>Czas ostatniego logowania</span>),
      (<span>IP ostatniego logowania</span>)
    ];
    const body = this.state.users.map((u,k) => this.renderSingleUser(u,k));
    const pagination = {
      page,total,limit
    }
    return (
      <AdminTableComponent
        header={header}
        body={body}
        pagination={pagination}
        filter={filter}
        updateCriteria={this.updateCriteria}
        isFetching={isFetching}
      />
    )
  }

  render(){
    const {isFetching} = this.state;
    return (
      <div className="users-list">
        {isFetching && (<LoaderComponent/>)}
        <h3>Użytkownicy:</h3>
        {this.renderUsers()}
      </div>
    )
  }
}
