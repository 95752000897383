import React from 'react';
import { Button } from 'react-bootstrap';
import './HomeComponent.scss';
import Logo from '../../assets/logo_blank.png';
import PartnersImage from '../../assets/partners.png';
import LoaderComponent from '../../Loader/LoaderComponent';
import { Link, Navigate } from 'react-router-dom';
import {fetchData} from '../../services';
const {REACT_APP_BACKEND_OPEN_URL} = process.env;

export default class MainComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      relations: [],
      isLoading: false,
    }
    this.fetchUser = this.fetchUser.bind(this);
  }

  componentDidMount(){
    this.fetchUser();
  }

  fetchUser(){
    this.setState({isLoading:true});
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/user/api/me/relations`)
      .then(data=>{
        if(data) this.setState({
          relations: data.relations,
        })
      })
      .finally(()=>{
        this.setState({isLoading:false});
      })
  }

  renderRelation(r,k){
    let label = r.nickname || r.email;
    if(r.portal) label+=` (${r.portal})`;
    return (
      <div key={k}>
        <Link to={`/find-out/app/relation-details/${r.id}`}>{label}</Link>
      </div>
    );
  }

  render(){
    const {relations,isLoading} = this.state;
    const {isLoggedIn} = this.props;
    return (
      <div className="main-component">
        {!isLoggedIn && (<Navigate to="/find-out/login" redirect={true} />)}
        <Link to="/"><img src={Logo} alt="Logo" /></Link>
        <Button className="my">
          <Link to="/find-out/app/my-account">Moje konto</Link>
        </Button>
        <Button variant="danger" onClick={this.addRelation}>
          <Link to="/find-out/app/add-relation">+ Dodaj partnera</Link>
        </Button>
        <h6><span>Lista partnerów</span></h6>
        <div className="partners-list">
          {isLoading ? (<LoaderComponent/>) :
            !relations.length ? (<div><h5>Aktualnie brak</h5><img src={PartnersImage} alt="Partners" /></div>) :
            relations.map((r,k) => this.renderRelation(r,k)
          )}
        </div>
        <Link className="mainmenu" to="/logout"><span>Wyloguj się</span></Link>
      </div>
    );
  }
}
