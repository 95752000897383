import React from 'react';
import { Card, Alert } from 'react-bootstrap';
import { useParams, Navigate } from 'react-router-dom';
import {fetchData} from '../../services';
import './HomeComponent.scss';
const {REACT_APP_BACKEND_OPEN_URL} = process.env;

const NUMBER_OF_REFRESHES = 5;

function withParams(Component){
  return props => <Component {...props} params={useParams()}/>;
}

class PaymentVerificationComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      success: false,
      isLoading: false,
      countdown: 0,
      numberOfRefresh: 0,
    }
    this.fetchItem = this.fetchItem.bind(this);
    this.startCountdown = this.startCountdown.bind(this);
    this.renderCountdown = this.renderCountdown.bind(this);
  }

  componentDidMount(){
    this.fetchItem();
  }

  fetchItem(){
    const {id} = this.props.params;
    this.setState({
      isLoading: true,
    });
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/user/api/payment/payment-status/${id}`)
      .then(data => {
        this.setState({
          success: data.success,
        })
        if(!data.success) this.startCountdown();
      })
      .catch(err=>console.warn(err))
      .finally(() => this.setState({
        isLoading: false,
      }));
  }

  startCountdown(){
    let {countdown,numberOfRefresh} = this.state;
    let addRefresh=0;
    if(0===countdown){
      countdown = 11;
      addRefresh = 1;
    }
    this.setState({
      countdown: --countdown,
      numberOfRefresh: numberOfRefresh+addRefresh,
    },() => {
      if(0===countdown){
        if(NUMBER_OF_REFRESHES>numberOfRefresh){
          this.fetchItem();
        }
      }
      else setTimeout(() => this.startCountdown(), 1000);
    })
  }

  renderCountdown(){
    let items = new Array(this.state.countdown).fill(".").join("");
    return (<span>{items}</span>);
  }

  redirect(){
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let replaceTo = '/find-out';
    if (/android/i.test(userAgent)) {
        replaceTo = 'findout://myAccount';
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        replaceTo = 'find-out://myAccount';
    }
    window.location.replace(replaceTo);
  }

  renderSuccessfulPayment(){
    return (
      <span>Płatność została potwierdzona, <span className="clickable" onClick={this.redirect}>przejdź do Twojego panelu.</span></span>
    );
  }

  render(){
    const {isLoading,success,numberOfRefresh}=this.state;
    let message = !!success ? this.renderSuccessfulPayment() : "Oczekujemy na weryfikację płatności...";
    return (
      <div className="home-component">
        <Card>
          <Card.Header>Status zamówienia pakietu</Card.Header>
          <Card.Body>
            {NUMBER_OF_REFRESHES > numberOfRefresh && (<Card.Title>{this.renderCountdown()}</Card.Title>)}
            <Card.Text>
              {NUMBER_OF_REFRESHES > numberOfRefresh && (<small>({numberOfRefresh})</small>)}
              {!isLoading && (<Alert variant={success ? 'success':'warning'}>{message}</Alert>)}
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default withParams(PaymentVerificationComponent);
