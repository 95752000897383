import React from 'react';
import './ResetComponent.scss';
import Logo from '../assets/logo.png';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';
import {fetchData} from '../services';
const {REACT_APP_BACKEND_OPEN_URL} = process.env;

export default class ResetComponent extends React.Component {

  constructor(props){
    super(props);
    this.state={
      email:'',
      sendingDone: false,
    }
    this.updateItem = this.updateItem.bind(this);
    this.submitReset = this.submitReset.bind(this);
  }

  updateItem(item,ev){
    this.setState({[item]:ev.target.value});
  }

  submitReset(ev){
    ev.preventDefault();
    let data = {'email':this.state.email};
    this.setState({sendingDone:false});
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/reset-password-init`,'POST',data)
      .then(data => !!data.success && this.setState({sendingDone:true,email:''}))
      .catch(error=>console.warn(error));
  }

  render(){
    return (
      <div className="login-panel">
        {this.state.sendingDone && (<Alert key="info" variant="info">Wysłaliśmy na Twojego maila informację z linkiem do resetu hasła.</Alert>)}
        <Form onSubmit={this.submitReset}>
          <img src={Logo} className="logo" alt="Logo"/>
          <Form.Group>
            <Form.Control placeholder="Email" type="text" name="email" onChange={(ev)=>this.updateItem('email',ev)} />
          </Form.Group>
          <div className="login-link">
            <Link to="/find-out/login">Jednak pamiętam! Zaloguj się</Link>
          </div>
          <Button variant="danger" type="submit">Zresetuj hasło</Button>
          <div>
            <ul>
             <li className="register-link"><Link to="/find-out/register">Zarejestruj się</Link></li>
            </ul>
          </div>
        </Form>
      </div>
    )
  }
}
