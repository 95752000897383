import React from 'react';
import './BlogPagesComponent.scss';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Outlet, Navigate, useParams } from 'react-router-dom';
import {fetchData} from '../services';
import LoaderComponent from '../Loader/LoaderComponent';
import {Card} from 'react-bootstrap';
import FrontEndHeaderComponent from '../FrontEndHeaderComponent';
import FrontEndFooterComponent from '../FrontEndFooterComponent';
const {REACT_APP_BACKEND_OPEN_URL} = process.env;

function withParams(Component){
  return props => (<Component props={props} params={useParams()} />);
}

class BlogPagesComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      isFetching: false,
      categories: [],
      getBlogDescription: '',
    }
    this.fetchCategories = this.fetchCategories.bind(this);
    this.getBlogDescription = this.getBlogDescription.bind(this);
  }

  componentDidMount(){
    this.fetchCategories();
    this.getBlogDescription();
  }

  fetchCategories(){
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/blog-category/all`)
      .then(data=>data.success && this.setState({
        categories: data.categories,
      }))
      .catch(err => console.warn(err));
  }

  getBlogDescription(){
    fetchData(`${REACT_APP_BACKEND_OPEN_URL}/api/settings/get-blog-description`)
      .then(data=>data.success && this.setState({
        blogDescription: data.description,
      }))
      .catch(err => console.warn(err));
  }

  renderCategory(c,n){
    const {category} = this.props.params;
    const isDisabled = c.id === parseInt(category);
    return (
      <Nav.Link disabled={isDisabled} key={`link-${n}`} href={`/historie/c/${c.id}/${c.queryString}`}>{c.name}</Nav.Link>
    );
  }

  renderCategoriesMenu(){
    const {categories} = this.state;
    const {category} = this.props.params;
    const allCat = 0 === parseInt(category);
    return (
      <Navbar bg="light" data-bs-theme="light">
        <Container>
          <Navbar.Brand href="#home">Kategorie:</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link disabled={allCat} href={`/historie/c/0/wszystkie-kategorie`}>Wszystkie kategorie</Nav.Link>
            {categories.map((c,n) => this.renderCategory(c,n))}
          </Nav>
        </Container>
      </Navbar>
    );
  }

  render(){
    const {isFetching,blogDescription} = this.state;
    const {isAdmin} = this.props;
    return (
      <main className="front-end">
        {isFetching && (<LoaderComponent />)}
        {isAdmin && (<Navigate to="/admin" redirect={true} />)}
        <FrontEndHeaderComponent />
        <section className="love-stories">
          <h1>Historie randkowe</h1>
          {this.renderCategoriesMenu()}
          {!!blogDescription && (
            <Card>
              <Card.Body>
                <Card.Text>{blogDescription}</Card.Text>
              </Card.Body>
            </Card>
          )}
          <Outlet />
        </section>
        <FrontEndFooterComponent />
      </main>
    )
  }
}

export default withParams(BlogPagesComponent);
